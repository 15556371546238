@import "../../variables";

:local {
  .InputContainer {
    display: block;
    width: 100%;
  }

  .Input {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 2px solid $form-passive;
    border-radius: 6px;
    transition: border .3s linear;
    font-family: "Yandex Sans Text", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #000;
    padding: 10px;
    margin-top: 12px;
    outline: none !important;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $form-passive;
    }

    &:focus {
      border: 2px solid $form-active;
    }

    &.Filled {
      border: 2px solid $primary-color;
    }

    &.Error {
      border: 2px solid $form-error;
    }

    &.Responsible {
      @include bp(desktop-large) {
        padding: 24px;
      }
    }
  }

  .ErrorMessage {
    display: none;
    margin-top: 5px;
    padding-left: 5px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Yandex Sans Text", sans-serif;
    color: $form-error;

    &.Visible {
      display: block;
    }
  }
}