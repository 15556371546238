@import "../variables";

.DropDown-root {
  font-family: "Yandex Sans Text", sans-serif;
}

.Dropdown-control {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border: 2px solid $form-passive;
  border-radius: 6px;
  transition: border .3s linear;
  font-family: "Yandex Sans Text", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  padding: 10px;
  margin-top: 12px;

  &:focus {
    border: 2px solid $form-active;
  }

  &.Error {
    border: 2px solid $form-error;
  }
}

.Dropdown-arrow {
  top: 20px;
  right: 17px;

  @include bp(desktop-large) {
    top: 34px;
  }
}

.Dropdown-menu {
  border: 2px solid $form-passive;
  border-top: 0;
  width: 228px;
  height: 200px;
  right: 0;
  left: auto;
}

.Dropdown-placeholder {
  color: $form-passive;

  &.is-selected {
    color: $primary-color;
  }
}

.Dropdown-control {
  @include bp(desktop-large) {
    padding: 24px;
  }
}