@font-face {
  font-family: 'Suisse Intl';
  src: url('./fonts/Suisse/SuisseIntl-Black.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-BlackItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Bold.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-BoldItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Book.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-BookItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Light.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-LightItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Medium.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-MediumItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Regular.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-RegularItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-SemiBold.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-SemiBoldItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Thin.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-ThinItalic.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-Ultralight.otf') format('otf'),
  url('./fonts/Suisse/SuisseIntl-UltralightItalic.otf') format('otf');
}

@font-face {
  font-family: 'Yandex Sans Text';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: url('./fonts/Yandex_font/YandexSansText-Regular.eot');
  src: url('./fonts/Yandex_font/YandexSansText-Regular.eot?#iefix')
  format('embedded-opentype'),
  url('./fonts/Yandex_font/YandexSansText-Regular.woff2') format('woff2'),
  url('./fonts/Yandex_font/YandexSansText-Regular.woff') format('woff'),
  url('./fonts/Yandex_font/YandexSansText-Regular.ttf') format('truetype');
}