@import "../../fonts.scss";
@import "../../variables";

:local {
  .Button {
    color: #fff;
    background: $primary-color;
    border-radius: 6px;
    font-family: "Yandex Sans Text", sans-serif;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color .3s linear;
    width: 140px;
    height: 40px;
    font-size: 18px;
    line-height: 24px;

    &:hover {
      background: $active-color;
    }

    &.Big {
      height: 64px;
      width: 300px;
      font-size: 20px;
    }

    &.UltraBig {
      width: 456px;
      height: 95.67px;
      font-size: 30px;
      line-height: 24px;
    }

    &.Middle {
      height: 60px;
      width: 250px;
      font-size: 20px;
    }

    &.Inverted {
      background-color: #fff;
      border: 1px solid #000;
      color: #000;

      &:hover {
        background-color: rgba($active-color, 0.1);
      }
    }
  }
}