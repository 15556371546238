@import "../../fonts.scss";
@import "../../variables";

:local {
  .Header {
    position: fixed;
    z-index: 1;
    top: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 80px;
    background: #fff;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding: 0 24px;
    box-sizing: border-box;

    @include bp(mobile) {
      flex-direction: column;
      height: auto;
      position: relative;
      transform: none !important;
    }

    &.Flipped {
      .Logo {
        flex-direction: row-reverse !important;
      }
    }
  }

  .Logo {
    display: flex;
    flex-direction: row;
    height: 100%;
    position: relative;

    .Disclaimer {
      position: absolute;
      font-family: 'Yandex Sans Text', sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 96%;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      text-align: center;
      right: -130px;
      top: 20px;

      color: #000;

      opacity: 0.4;
    }

    .LogoYandex, .LogoScooter {
      height: 100%;
      text-decoration: none;
      display: flex;
      align-items: center;
    }

    .LogoScooter {
      margin-left: 8px;

      img {
        height: 23px;
      }
    }

    .LogoYandex img {
      height: 25px;
    }
  }

  .Nav {
    display: flex;
    align-items: center;
    transition: transform .3s ease-out;

    @include bp(mobile) {
      margin-top: 24px;
      margin-bottom: 50px;
    }

    & :first-child {
      margin-right: 30px;
    }

    .Link {
      text-decoration: none;
      box-sizing: border-box;
      font-family: "Yandex Sans Text", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: $primary-color;
      background: none;
      outline: none;
      border: 0;
      cursor: pointer;

      &:hover {
        color: $active-color;
      }
    }
  }

  .SearchInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    bottom: 0;
    top: 0;
    right: -490px;
    transition: transform .3s ease-in;

    @include bp(mobile) {
      pointer-events: none;
    }

    .Button {
      height: 48px;
      width: 70px;
      margin-left: 8px;

      @include bp(mobile) {
        pointer-events: all;
      }
    }


    .Input {
      margin: 0;

      @include bp(mobile) {
        pointer-events: all;
      }
    }
  }

  .ShowSearch {
    .Nav {
      transform: translateX(400px);
    }

    .SearchInput {
      transform: translateX(-525px);
    }
  }
}