$primary-color: #1A1B22;
$active-color: #3A3C3C;

$form-passive: #AFB4B7;
$form-active: #0F53FB;
$form-error: #FD6E70;

@mixin bp($point) {
  @if $point == desktop-large {
    @media (min-width: 1920px) { @content; }
  }
  @if $point == desktop-medium {
    @media (min-width: 1280px) { @content; }
  }
  @if $point == desktop {
    @media (min-width: 1024px) { @content; }
  }
  @else if $point == tablet {
    @media (max-width: 1023px) { @content; }
  }
  @else if $point == mobile {
    @media (max-width: 767px) { @content; }
  }
  @else if $point == mobile-small {
    @media (max-width: 480px) { @content; }
  }
  @else if $point == widgetFull {
    @media (max-width: 651px) { @content; }
  }
}