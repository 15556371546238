@import "../../variables";
@import "../../fonts";

:local {
  .Content {
    display: flex;
    flex-direction: column;
    margin: 23px 66px;

    @include bp(mobile) {
      margin: 23px 0;
      padding: 0 24px;
    }

    .Form {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include bp(mobile) {
        flex-direction: column;
      }

      & > :first-child {
        margin-right: 75px;
        display: flex;
        width: calc(100% - 75px - 250px);

        @include bp(mobile) {
          width: 100%;
          margin: 0 0 10px;
        }

        .Input {
          margin-top: 0;
          height: 60px;
        }
      }
    }

    .OrderColumns {
      margin-top: 44px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.Straight {
        flex-direction: column;
      }

      @include bp(mobile) {
        flex-direction: column;
      }

      .OrderInfo {
        display: flex;
        flex-direction: column;
        width: 400px;

        @include bp(mobile) {
          width: auto;
        }

        .Row {
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }

        .Title {
          color: #737373;
          font-family: "Yandex Sans Text", sans-serif;
          font-size: 18px;
          line-height: 24px;
        }

        .Separator {
          display: block;
          width: 100%;
          height: 1px;
          box-sizing: border-box;
          border-top: 1px solid #90959A;
          margin: 12px 0 24px;
        }

        .Value {
          color: #000;
          font-family: "Yandex Sans Text", sans-serif;
          font-size: 18px;
          line-height: 24px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          max-width: 250px;
          text-overflow: ellipsis;
          text-align: right;

          .Circle {
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            margin-right: 5px;
          }
        }
      }

      .OrderRoadmap {
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include bp(mobile) {
          margin-top: 40px;
        }

        $statusActiveColor: $primary-color;
        $statusPassiveColor: rgba($primary-color, .4);
        $statusErrorColor: $form-error;

        .OrderBrick {
          flex-direction: row;
          display: flex;
          width: 500px;
          height: 142px;
          margin-left: auto;
          margin-right: auto;
          justify-content: space-between;

          @include bp(mobile) {
            width: 100%;
            box-sizing: border-box;
          }

          .OrderCircle {
            box-sizing: border-box;
            background: #fff;
            width: 64px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            line-height: 20px;
            font-family: "Yandex Sans Text", sans-serif;
            border: 1px solid $statusPassiveColor;
            color: $statusPassiveColor;
            height: 64px;
            position: relative;
            z-index: 2;

            &:after {
              width: 1px;
              z-index: 1;
              box-sizing: border-box;
              content: '|';
              color: transparent;
              border: 1px solid $statusPassiveColor;
              border-left: 0;
              display: block;
              position: absolute;
              bottom: -90px;
              left: 30px;
              height: 90px;
            }

            &.Lineless {
              &:after {
                display: none;
              }
            }

            @include bp(mobile) {
              &:after {
                display: none;
              }
            }
          }

          .OrderInfo {
            display: flex;
            flex-direction: column;

            @include bp(mobile) {
              width: calc(100% - 80px);
            }

            .Order {
              font-family: 'Yandex Sans Text', sans-serif;
              color: $statusPassiveColor;
              font-size: 30px;
              line-height: 36px;
            }

            .OrderDescription {
              font-size: 18px;
              line-height: 26px;
              font-family: "Yandex Sans Text", sans-serif;
              color: $statusPassiveColor;
              width: 360px;

              @include bp(mobile) {
                width: auto;
              }
            }
          }

          &.Highlight {
            .OrderCircle, .Order, .OrderDescription {
              color: $statusActiveColor;
            }

            .OrderCircle {
              border-color: $statusActiveColor;
              border-width: 2px;

              &:after {
                border-color: $statusActiveColor;
                border-width: 2px;
              }
            }
          }

          &.Overtime {
            .OrderCircle, .Order, .OrderDescription {
              color: $statusErrorColor;
            }

            .OrderCircle {
              border-color: $statusErrorColor;
              border-width: 2px;

              &:after {
                border-color: $statusErrorColor;
                border-width: 2px;
                bottom: -118px;
                height: 118px;
              }
            }
          }
        }
      }
    }
  }

  .NotFound {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 122px 66px 0;

    & > img {
      width: 100%;
    }
  }
}