@import "../../fonts";
@import "../../variables";

:local {
  .Content {
    display: block;
    margin: 20px auto;
    max-width: 720px;

    @include bp(desktop-large) {
      max-width: 1000px;
    }

    @include bp(mobile) {
      max-width: 100%;
      padding: 0 24px;
      box-sizing: border-box;
      margin-top: 0;
    }

    .Header {
      font-family: "Yandex Sans Text", sans-serif;
      color: $primary-color;
      font-size: 45px;
      line-height: 96%;

      @include bp(desktop-large) {
        font-size: 68px;
      }

      @include bp(mobile) {
        font-size: 26px;
      }
    }

    .Form {
      display: block;
      margin-top: 42px;
    }

    .NextButton {
      display: flex;
      width: 100%;
      margin-top: 60px;
      justify-content: flex-end;
    }

    .Buttons {
      display: flex;
      width: 100%;
      margin-top: 60px;
      justify-content: space-between;
    }
  }

  .MixedDatePicker {
    display: block;
    position: relative;

    .Icon {
      display: block;
      pointer-events: none;
      position: absolute;
      right: 17px;
      top: 28px;

      @include bp(desktop-large) {
        top: 42px;
      }
    }
  }

  .SelectOption {
    display: flex !important;
    flex-direction: row !important;
    border: 0;
    padding: 3px 3px 3px 10px;
    align-items: center;

    .Circle {
      display: flex;
      width: 15px;
      height: 15px;
      border-radius: 50%;
    }

    .Text {
      padding-left: 6px;
      font-family: 'Yandex Sans Text', sans-serif;
      font-size: 12px;
      color: #000;
      display: flex !important;
      align-items: center !important;
      vertical-align: middle;
    }
  }

  .Checkboxes {
    display: block;
    box-sizing: border-box;
    width: 100%;
    border: 2px solid $form-passive;
    border-radius: 6px;
    transition: border .3s linear;
    font-family: "Yandex Sans Text", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: $form-passive;
    padding: 10px;
    margin-top: 12px;

    &.FilledContainer {
      border: 2px solid $primary-color;
      color: $primary-color;
    }
  }

  .Title {
    font-family: "Yandex Sans Text", sans-serif;
    font-size: 18px;
    line-height: 24px;
  }

  .Overlay {
    position: fixed;
    background-color: rgba(#000, .35);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }

  .MetroError {
    margin-top: 5px;
    padding-left: 5px;
    font-size: 18px;
    line-height: 24px;
    font-family: "Yandex Sans Text", sans-serif;
    color: $form-error;
  }

  .Filled {
    input {
      border: 2px solid $primary-color;
    }
  }

  .UnderError {
    input {
      border: 2px solid $form-error;
    }
  }

  .FilledDate {
    & > div {
      border: 2px solid $primary-color;
    }
  }

  .Modal {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 900px;
    height: 400px;
    position: fixed;
    border: 1px solid #000;
    box-sizing: border-box;
    border-radius: 6px;
    z-index: 99;
    left: 50%;
    top: 50%;
    background-color: #fff;
    transform: translateX(-450px) translateY(-200px);
    padding-top: 73px;

    @include bp(desktop-large) {
      height: 560px;
      width: 1350px;
      transform: translateX(-675px) translateY(-270px);
    }

    &.Inverted {
      height: 560px;
      width: 1350px;
      transform: translateX(-675px) translateY(-270px);

      @include bp(desktop-large) {
        width: 900px;
        height: 400px;
        transform: translateX(-450px) translateY(-200px);
      }
    }

    @include bp(mobile) {
      transform: translateY(-200px);
      width: 100%;
      position: absolute;
      top: 50%;
      left: 0;
      padding: 0 12px;
    }

    .ModalHeader {
      font-family: 'Yandex Sans Text', sans-serif;
      font-size: 35px;
      line-height: 50px;
      color: #000;
      text-align: center;

      @include bp(mobile) {
        font-size: 24px;
        line-height: 30px;
      }

      @include bp(desktop-large) {
        font-size: 52px;
      }

      .Text {
        margin-top: 13px;
        box-sizing: border-box;
        font-size: 26px;
        line-height: 25px;
        max-width: 495px;
        text-align: justify;
        min-height: 50px;

        @include bp(mobile) {
          font-size: 18px;
        }
      }
    }

    .Buttons, .NextButton {
      padding-top: 3px;
      max-width: 577px;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    .NextButton {
      justify-content: flex-end;
    }
  }
}