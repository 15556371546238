@import "../../variables";

:local {
  .Input {
    border: 1px solid $primary-color;
    position: relative;
    top: 2px;
    margin-right: 10px;
  }

  .Label {
    position: relative;
    vertical-align: middle;
    font-family: "Yandex Sans Text", sans-serif;
    font-size: 18px;
    line-height: 24px;
    cursor: pointer;

    span {
      position: relative;
      cursor: pointer;
      padding-left: 32px;
      top: 0;
    }
  }
}
