@import "../../fonts";
@import "../../variables";

:local {
  .HomePage {
    background-color: #fff;
    display: block;
    position: relative;
    overflow-x: hidden;
  }

  .FirstPart {
    display: block;
    position: relative;

    .Header {
      display: block;
      position: fixed;
      top: 105px;
      left: 72px;
      box-sizing: border-box;
      color: $primary-color;
      font-size: 64px;
      max-width: 420px;
      line-height: 96%;

      @include bp(desktop-large) {
        font-size: 96px;
        max-width: 680px;
      }

      @include bp(mobile) {
        position: static;
        font-size: 30px;
        font-weight: bold;
        padding: 0 24px;
      }

      .Italic {
        font-style: italic;
      }

      .SubHeader {
        font-family: "Yandex Sans Text", sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: $primary-color;
        max-width: 500px;
        display: block;
        margin-top: 25px;

        @include bp(desktop-large) {
          font-size: 28px;
          line-height: 35px;
          margin-top: 35px;
          max-width: 900px;
        }

        @include bp(mobile) {
          position: static;
          font-weight: normal;
          opacity: 1 !important;
          display: block !important;
          margin-bottom: 40px;
        }
      }
    }

    .BluePrint, .Scooter {
      position: fixed;
      width: 788px;
      height: 591px;
      top: 122px;
      right: -125px;

      @include bp(desktop-large) {
        width: 1300px;
        height: 823px;

        & > img {
          width: 80%;
        }
      }

      @include bp(mobile) {
        position: static;
        display: none;
        transform: none !important;
      }
    }

    .Scooter {
      top: 94px;
      right: -81px;
      width: 800px;
      height: 600px;

      & > img {
        height: 100%;
      }

      @include bp(desktop-large) {
        width: 1300px;
        height: 828px;
        top: 78px;
        right: -220px;

        & > img {
          height: auto;
        }
      }

      @include bp(mobile) {
        width: 100%;
        padding: 0 24px;
        display: flex;
        opacity: 1 !important;
        box-sizing: border-box;
        height: auto;

        & > img {
          width: 100%;
        }
      }
    }

    .ArrowDown {
      position: absolute;
      bottom: 50px;
      left: 72px;
      transition: opacity .15s linear;

      @include bp(mobile) {
        display: none;
      }

      &.Animated {
        -webkit-animation-duration: .5s;
        animation-duration: .5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        -webkit-animation-iteration-count: infinite;
      }

      @-webkit-keyframes bounce {
        0%, 100% {
          -webkit-transform: translateY(0);
        }
        50% {
          -webkit-transform: translateY(-5px);
        }
      }
      @keyframes bounce {
        0%, 100% {
          transform: translateY(0);
        }
        50% {
          transform: translateY(-5px);
        }
      }

      &.Bounce {
        -webkit-animation-name: bounce;
        animation-name: bounce;
      }
    }

    .BluePrint {
      right: -72px;
      top: 92px;

      @include bp(desktop-large) {
        top: 76px;
        right: -205px;
        height: 628px;
        width: 1280px;
      }
    }

    .Table {
      position: fixed;
      display: flex;
      flex-direction: column;
      max-width: 415px;
      top: 375px;
      left: 72px;
      width: 100%;
      box-sizing: border-box;

      @include bp(desktop-large) {
        top: 500px;
        max-width: 650px;
      }

      @include bp(mobile) {
        position: static;
        opacity: 1 !important;
        transform: none !important;
        padding: 0 24px;
      }

      .Separator {
        display: flex;
        height: 1px;
        width: 100%;
        border-bottom: 1px solid $primary-color;
        margin: 10px 0;

        @include bp(desktop-large) {
          margin: 9px 0;
        }
      }

      .Row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .FirstRow {
        margin-top: 10px;
      }

      .Column {
        font-size: 18px;
        line-height: 24px;
        font-family: 'Yandex Sans Text', Arial, sans-serif;
        display: flex;
        align-items: center;
        margin: 10px 0;

        @include bp(desktop-large) {
          font-size: 28px;
          line-height: 24px;
          margin: 23px 0;
        }
      }

      .Column + .Column {
        text-align: right;
      }
    }
  }

  .SecondPart {
    position: relative;

    @include bp(mobile) {
      display: none;
    }
  }

  .ThirdPart {
    display: block;
    padding-top: 0;

    @include bp(desktop-large) {
      margin-top: 190px;
    }

    .SubHeader {
      display: block;
      font-family: "Yandex Sans Text", sans-serif;
      font-size: 32px;
      line-height: 96%;
      color: $primary-color;
      padding-left: 72px;

      @include bp(desktop-large) {
        font-size: 48px;
      }
    }

    .RoadMap {
      display: flex;
      justify-content: center;
      padding-top: 24px;
      flex-direction: column;

      @include bp(desktop-large) {
        margin-top: 15px;
      }

      .StatusBrick {
        flex-direction: row;
        display: flex;
        width: 550px;
        margin-bottom: 84px;
        margin-left: auto;
        margin-right: auto;
        justify-content: space-between;
        padding-left: 243px;

        @include bp(desktop-large) {
          width: 870px;
          justify-content: flex-start;
          margin-bottom: 123px;
          padding-left: 273px;
        }

        @include bp(mobile) {
          width: 100%;
          padding: 0 24px;
          box-sizing: border-box;
        }

        .StatusCircle {
          box-sizing: border-box;
          background: #fff;
          width: 67px;
          height: 67px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 20px;
          line-height: 20px;
          font-family: "Yandex Sans Text", sans-serif;
          border: 1px solid $primary-color;
          color: $primary-color;
          position: relative;
          z-index: 2;

          @include bp(desktop-large) {
            width: 100px;
            height: 100px;
            margin-right: 40px;
          }

          &:after {
            width: 1px;
            z-index: 1;
            box-sizing: border-box;
            content: '|';
            color: transparent;
            border: 1px solid $primary-color;
            border-left: 0;
            display: block;
            position: absolute;
            bottom: -140px;
            left: 31px;
            height: 140px;

            @include bp(desktop-large) {
              left: 50px;
            }
          }

          &.Lineless {
            &:after {
              display: none;
            }
          }

          @include bp(mobile) {
            &:after {
              display: none;
            }
          }
        }

        .StatusInfo {
          display: flex;
          flex-direction: column;

          @include bp(mobile) {
            width: calc(100% - 80px);
          }

          @include bp(desktop-large) {
            padding-left: 10px;
            padding-top: 10px;
          }

          .Status {
            font-family: 'Yandex Sans Text', sans-serif;
            color: #000;
            font-size: 30px;
            line-height: 36px;

            @include bp(desktop-large) {
              font-size: 45px;
              line-height: 36px;
              margin-bottom: 10px;
            }
          }

          .StatusDescription {
            font-size: 18px;
            line-height: 26px;
            font-family: "Yandex Sans Text", sans-serif;
            color: $primary-color;
            width: 450px;

            @include bp(desktop-large) {
              font-size: 28px;
              width: 700px;
              padding-top: 10px;
            }

            @include bp(mobile) {
              width: auto;
            }
          }
        }
      }

      .FinishButton {
        display: flex;
        margin: 0 auto 40px;
        transform: translateX(-28px);
      }
    }
  }

  .FourPart {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 72px;
    padding-left: 72px;
    margin-bottom: 200px;
    box-sizing: border-box;
    margin-top: 120px;

    @include bp(mobile) {
      flex-direction: column;
      padding: 0 24px;
    }

    @include bp(desktop-large) {
      margin-top: 227px;
    }

    .SubHeader {
      display: flex;
      font-family: "Yandex Sans Text", sans-serif;
      font-size: 32px;
      line-height: 96%;
      color: $primary-color;
      max-width: 400px;

      @include bp(desktop-large) {
        font-size: 48px;
        max-width: 600px;
      }
    }

    .FAQ {
      display: flex;
      margin-top: 20px;
      box-sizing: border-box;

      @include bp(desktop-large) {
        width: 900 pxц;
      }
    }
  }
}