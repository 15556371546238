@import "variables";
@import "fonts";

:local {
  .App {
    margin-top: 80px;

    @include bp(mobile) {
      margin-top: 24px;
    }
  }

  .App * {
    font-family: "Yandex Sans Text", sans-serif !important;
  }

  .CookieConsent {
    background-color: #fff !important;
    border: 4px solid black;
    box-sizing: border-box;
    height: 120px;
    display: flex !important;
    align-items: center !important;
    padding: 0 73px;

    .CookieText {
      font-size: 30px;
      line-height: 36px;
      color: #000;
    }

    .CookieButton {
      background: $primary-color !important;
      border: 1px solid $primary-color !important;
      box-sizing: border-box;
      border-radius: 6px !important;
      font-size: 20px !important;
      line-height: 24px !important;
      color: #fff !important;
      width: 250px;
      height: 60px;
      transition: background-color .3s linear;

      &:hover {
        background: $active-color !important;
      }
    }
  }
}